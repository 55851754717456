.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

label.MuiInputLabel-outlined.MuiInputLabel-shrink{
  background-color: white;
  margin-left: 10;
  margin-right: 10;
  padding-left: 10;
  padding-right: 10;
}

